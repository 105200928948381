@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.icon {
  mask-size: 100% !important;
  mask-repeat: no-repeat !important;

  &.icon-refresh {
    mask: url('/icons/refresh.svg') no-repeat 50% 50%;

    &.animate {
      animation: spin 1s linear infinite;
    }
  }

  $icons: 'check-circle', 'close', 'info-circle', 'sad', 'smile', 'check', 'copy', 'download', 'arrow-down',
  'pencil', 'sun', 'moon', 'menu', 'fr', 'en', 'website', 'desktop', 'mail', 'phone', 'picture',
  'binoculars', 'book', 'calendar-check', 'code', 'credit-card', 'database', 'diagram-project',
  'envelopes-bulk', 'file-code', 'file', 'gauge-high', 'handshake-angle', 'icons', 'key', 'language',
  'laptop-code', 'link', 'magnifying-glass-chart', 'minimize', 'tags', 'terminal', 'toggle-on', 'user-ninja',
  'user', 'window-restore', 'server', 'car-tunnel', 'timeline', 'arrow-up';

  @each $icon in $icons {
    &.icon-#{$icon} {
      mask: url('/icons/#{$icon}.svg');
    }
    &.bg-#{$icon} {
      background: url('/icons/#{$icon}.svg') no-repeat;
      background-size: 100%;
    }
  }
}
