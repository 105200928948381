@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.brand {
  mask-size: 100% !important;
  mask-repeat: no-repeat !important;

  $icons: "spotify", 'twitch', 'twitter', 'linkedin', 'github', 'discord', 'dev', 'firefox-browser', 'html5';

  @each $icon in $icons {
    &.icon-#{$icon} {
      mask: url('/brands/#{$icon}.svg');
    }
    &.bg-#{$icon} {
      background: url('/brands/#{$icon}.svg') no-repeat;
      background-size: 100%;
    }
  }
}
